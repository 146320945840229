<template>
    <van-popup v-model:show="this.$props.show"
               :style="{ height: '80%' }"
               position="bottom"
               :close-on-click-overlay="false"
               :lazy-render='true'
               teleport="body"
               closeable
               @click-close-icon="closeWin"
    >
        <van-form @submit="onConfirm">
            <van-cell-group title="扫描箱号" inset>
                <van-field
                        v-model="packageCode"
                        name="packageCode"
                        label="箱号"
                        placeholder="请扫描包装二维码"
                        required
                        :rules="[{ required: true, message: '请扫描包装二维码' }]"
                        @focus="this.$emit('onfocus', 'packageCode')"
                ></van-field>
                <div style="margin: 4.26667vw;">
                    <van-button round block type="primary" native-type="submit">
                        确定
                    </van-button>
                </div>
            </van-cell-group>
        </van-form>
    </van-popup>
</template>

<script>
    import _ from "lodash";

    export default {
        name: "ScanQrCodeWin",

        props: {
            show: {
                default: false,
                type: Boolean
            },
        },

        emits: ['update:show', 'onConfirm'],

        data() {
            return {
                packageCode: ''
            };
        },
        created() {

        },
        methods: {
            onConfirm(value, index) {
                if (_.isEmpty(value)) {
                    return;
                }
                this.packageCode = '';
                this.$emit('update:show', false);
                this.$emit('onConfirm', value);
            },
            closeWin(value, index) {
                this.packageCode = '';
                this.$emit('update:show', false);
            }
        }
    }

</script>

<style scoped>

</style>