<template>
    <van-collapse v-model="activeNames">

        <van-collapse-item v-for="item in dataList" :key="item.id" :name="item.id" :readonly="!item.hasChildMenu"
                           style="color: #323233">
            <template #title>
                <van-swipe-cell>

                    <div class="item" @click="onClik(item)">
                        <div class="content">
                            <div class="title fs16">{{"物料：" + item['material.name']}}</div>
                            <div class="tags fs16">{{"包装：" + item['pack.name']}}</div>
                            <div class="tags fs16">{{"箱号：" + item['packageCode']}}</div>
                        </div>

                    </div>

                    <template #right>
                        <van-button square type="primary" text="扫描箱码" class="button" @click="scanBarcode(item)"/>

                    </template>
                </van-swipe-cell>
            </template>

            <test :data-list="item.childList" :load-child="loadChild" :scan-barcode="scanBarcode"/>
        </van-collapse-item>
    </van-collapse>
</template>

<script>


    export default {
        name: "test",
        components: {},

        emits: [],
        data() {
            return {
                activeNames: []
            }
        },
        props: {
            dataList: {
                type: Array,
                default: null
            },
            loadChild: {
                type: Function,
                default: null
            },
            scanBarcode: {
                type: Function,
                default: null
            }

        },
        methods: {
            onClik(item) {
                if (item.hasChildMenu) {
                    this.loadChild(item);
                }
            }
        }
    }
</script>

<style scoped>
    .van-collapse-item__content {
        padding: 1.2vw 0 2.2vw 0;
        color: var(--van-collapse-item-content-text-color);
        font-size: var(--van-collapse-item-content-font-size);
        line-height: var(--van-collapse-item-content-line-height);
        background: var(--van-collapse-item-content-background-color);
    }

    .item {
        display: flex;
        border-radius: 5px;
        flex-direction: row;
        box-shadow: 0 0 2px #ddd;
        align-items: center;
        padding: 10px;
        margin: 2px;
    }

    .item + .item {
        margin-top: 10px;
    }

    .item > .content {
        flex: 1;
    }

    .item .time {
        color: #666;
    }

    .button {
        height: 100%;
    }
</style>