<template>
    <van-cell-group title="绑定收货订单" inset>
        <Picker
                name="orderName"
                label="收货订单"
                placeholder="请选择收货订单"
                displayField="name"
                valueField="id"
                :required=false
                v-model:value="this.$data['orderName']"
                :defConfig="{
                    portName:'fr.order.ReceiptOrder.getReceiptOrders',
                    data: {'statusDic.code': 'RO_STATUS_CAR_ARRIVE'},
            }"
                :canSearch=true
                @onConfirm="onOrderCodeConfirm"
        />
    </van-cell-group>

    <van-cell-group title="包装物料" inset>
        <test :data-list="dataList"
              :scan-barcode="onRead"
              :loadChild="loadChild"
        ></test>
    </van-cell-group>

    <ScanQrCodeWin
            v-model:show="showEdit"
            ref="scanWin"
            @onConfirm="confirmEdit"
            @onfocus="onfocus"
    />
</template>
<script>
    import Test from "./test";
    import Picker from "../../components/Picker";
    import ScanQrCodeWin from "../rummage/ScanQrCodeWin";

    export default {
        name: "BindPackageCode",
        components: {Test, Picker, ScanQrCodeWin},
        data() {
            return {
                receiptOrderID: '',
                orderName: '',
                packageCode: '',
                activeNames: [],
                activeNames1: [],
                dataList: [],
                childList: [],
                focusModel: '',
                showEdit: false,
                nowItem: []
            }
        },
        created() {
            this.getScanCode();
        },
        methods: {
            onRead: function (item) {
                this.showEdit = true;
                this.nowItem = item;
            },
            onOrderCodeConfirm(value) {
                var me = this;
                this.receiptOrderID = value.id;
                var option = {
                    portName: 'fr.order.ReceiptOrderLevel.getReceiptOrderLevels',
                    data: {'receiptOrder.id': value.id},
                    needLoading: true,
                    successCallback: function (data) {
                        me.dataList = data.entities;
                    }
                };
                this.$sapi.callPort(option);
            },
            loadChild(value) {
                var me = this;
                var option = {
                    portName: 'fr.order.ReceiptOrderLevel.getReceiptOrderLevels',
                    data: {'receiptOrder.id': me.receiptOrderID, 'parentID': value.id},
                    needLoading: true,
                    successCallback: function (data) {
                        if (data.entities.length > 0) {
                            value.childList = data.entities;
                        }
                    }
                };
                this.$sapi.callPort(option);
            },
            onfocus(value) {
                this.focusModel = 'packageCode';
            },
            getScanCode: function () {
                if (!window.Capacitor) {
                    return;
                }
                var me = this;
                Capacitor.Plugins.Scanner.addListener('onScan', (data) => {
                    var barcode = data.barcode;
                    console.log(data.barcode);
                    switch (me.focusModel) {
                        case 'packageCode' :
                            me.$refs.scanWin.packageCode = barcode;
                            break;
                    }
                })
            },
            confirmEdit(value) {
                var me = this;
                value['receiptOrderLevelID'] = this.nowItem.id;
                var option = {
                    portName: 'fr.order.ReceiptOrderLevel.bindPackageCode',
                    data: value,
                    needLoading: true,
                    successCallback: () => {
                        this.$dialog.alert({
                            title: '',
                            message: '绑定成功',
                        }).then(() => {
                            me.nowItem.packageCode = value.packageCode;
                        });
                    }
                };
                this.$sapi.callPort(option);
            },
        }
    }

</script>

<style scoped>

</style>